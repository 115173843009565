import React from "react"
import Upload from "../../components/Upload/Upload"
import Layout from "../../components/Layout/Layout"

export default ({ location }) => (
  <Layout
    title="Upload Prescription"
    subtitle="Take a photo or attach a file to upload your prescription."
    process="flexmed"
    seoTitle="Upload Prescription"
  >
    <Upload
      backRoute="/flexmed/benefit-type"
      nextRoute="/flexmed/encode"
      flow="flexmed"
      location={location}
    />
  </Layout>
)
